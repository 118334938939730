import {useState} from 'react';

export default function Alarms() {
  const [alarms, setAlarms] = useState([{
    id: 1,
    timeStamp: new Date(),
    active: true,
    title: "An Alarm",
    description: "This is an alarm",
    things: ["thing1", "thing2"]
  }]);

  const handleAlarmClick = (id) => {
    console.log(`Alarm ${id} clicked`);
    // Go to a new page
    window.location.href = `/alarms/${id}`;
  }

  const headerClassName = "px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
  const tdClassName = "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900"

  return (
    <div className="container mx-auto">
      <table className="min-w-full bg-white">
      <thead>
        <tr>
        <th className={headerClassName}>Timestamp</th>
        <th className={headerClassName}>Title</th>
        <th className={headerClassName}>Description</th>
        <th className={headerClassName}>Things</th>
        </tr>
      </thead>
      <tbody>
        {alarms.map(alarm => (
        <tr key={alarm.id} className="cursor-pointer hover:bg-gray-100" onClick={() => handleAlarmClick(alarm.id)}>
          <td className={tdClassName}>{alarm.timeStamp.toLocaleString()}</td>
          <td className={tdClassName}>{alarm.title}</td>
          <td className={tdClassName}>{alarm.description}</td>
          <td className={tdClassName}>{alarm.things.join(", ")}</td>
        </tr>
        ))}
      </tbody>
      </table>
    </div>
    );
}