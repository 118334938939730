import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import WidgetA from './widgets/WidgetA';
import WidgetB from './widgets/WidgetB';
import VibrationSensor_1 from './widgets/VibrationSensor_1';
import WarehouseFloorplan_1 from './widgets/WarehouseFloorplan_1';
import Alarms from './widgets/Alarms';
import History from './widgets/History';
import SVGTest from './widgets/SVGTest';
import Temp from './widgets/Temp';
import Humidity from './widgets/Humidity';
import Shock from './widgets/Shock';
import Flood from './widgets/Flood';

function App(props) {

  console.log("Loading App.js");
  console.log("props", props);

  const getURLWidgetType = () => { 
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('widgetType');
  }

  const [widgetType, setWidgetType] = useState(props.widgetType || getURLWidgetType() || null);
  const widgets = {
    "WidgetA": WidgetA,
    "WidgetB": WidgetB,
    "VibrationSensor_1": VibrationSensor_1,
    "WarehouseFloorplan_1": WarehouseFloorplan_1,
    "Alarms": Alarms,
    "History": History,
    "SVGTest": SVGTest,
    "Temp": Temp,
    "Humidity": Humidity,
    "Shock": Shock,
    "Flood": Flood,

  };

    // Set the page title
    useEffect(() => {
      document.title = widgetType || "Select a widget";
    }, [widgetType]);

  // If the widget is found, render it
  if (widgetType && widgets[widgetType]) {
    const Widget = widgets[widgetType];
    return <Widget {...props} />;
  }

  return (<div>Select a widget
    <ul>
      {Object.keys(widgets).map((widgetType, index) => (
        <li key={index}>
          <button onClick={() => setWidgetType(widgetType)}>Select {widgetType}</button>
        </li>
      ))}
    </ul>
    
  </div>);
}

export default App;