import { useState } from "react";

const Shock = (props) => {
  const [shocks, setShocks] = useState([
    { name: "VLE-12", time: Date.now() - 1000 * 60 * 60 * 4.2, value: 4.218, ack: false },
    { name: "VLE-04", time: Date.now() - 1000 * 60 * 60 * 21.21, value: 5.691, ack: true },
    { name: "RRE-02", time: Date.now() - 1000 * 60 * 60 * 65.2, value: 4.904 , ack: true },
  ]);

  return (
    <div className="flex flex-col items-center justify-around">
      <div className="flex flex-col w-full">
            {/* Table Header */}
            <div className="flex bg-gray-200 font-bold">
                <div className="flex-1 p-2">Equipment</div>
                <div className="flex-1 p-2">Date</div>
                <div className="flex-1 p-2">Peak Shock Value</div>
                <div className="flex-1 p-2">Acknowledged</div>
            </div>
            {/* Table Rows */}
            {shocks.map((item, index) => (
                <div key={index} className="flex border-b">
                    <div className="flex-1 p-2">{item.name}</div>
                    <div className="flex-1 p-2 ">{new Date(item.time).toLocaleString()}</div>
                    <div className="flex-1 p-2">{item.value} G</div>
                    {item.ack ? (
                      <div className="flex-1 p-2 text-green-500 font-bold">Yes</div>
                    ) : (
                      <div className="flex-1 p-2 text-red-500 font-bold">No</div>
                    )}
                </div>
            ))}
        </div>
    </div>
  );
}

export default Shock;
