import React from 'react';
import { useState } from 'react';

const WidgetB = (props) => {
  const [count, setCount] = useState(0);
  console.log(JSON.stringify(props.ctx.widget));
  return (
    <div>
      <h1>Widget B</h1>
      <p>Count: {count}</p>
      <button onClick={() => setCount(count + 1)}>Increment</button>
    </div>
  );
};

export default WidgetB;