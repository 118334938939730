import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { LineChart } from '@mui/x-charts/LineChart';

const Temp = (props) => {
  const [data, setData] = useState([]);
  const [dataSet, setDataSet] = useState([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    let tmpDataSet = [];
    let tmpSeries = [];
    data.forEach((room) => {
      tmpSeries.push({ id: room.name, dataKey: room.name, type: "line", showMark: false, label: room.name });
      room.tempratures.forEach((temp) => {
        // Check if the date is already in the dataset
        let dateIndex = tmpDataSet.findIndex((value) => value.date === temp.time);
        if (dateIndex === -1) {
          tmpDataSet.push({ date: temp.time, [room.name]: temp.value });
        } else {
          tmpDataSet[dateIndex][room.name] = temp.value;
        }
      });
    });
    setDataSet(tmpDataSet);
    setSeries(tmpSeries);

  }, [data]);

  useEffect(() => {
    // Set dummy data
    const timePeriodMins = 15
    const timePeriodTotalMins = 24 * 60
    const timeIntervals = timePeriodTotalMins / timePeriodMins
    const temperatureData = {room1: [], room2: []};
    const now = Date.now();
    const minTemp = 11;
    const maxTemp = 25;

    for (let i = 0; i < (timeIntervals); i++) {
        const time = now + i * timePeriodMins * 60 * 1000;
        // Simulate temperature rise and fall using a sine wave pattern
        const angle = (i / timeIntervals) * Math.PI * 2;
        const value = Math.sin(angle) * (maxTemp - minTemp) / 2 + (maxTemp + minTemp) / 2;
        temperatureData.room1.push({ time, value: (value + (Math.random() * -0.5)) });
        let room2Value = value + (Math.random() * 0.9);
        if (room2Value > 22) {room2Value = room2Value - (room2Value - 22 + Math.random() * -0.5);}
        if (room2Value < 18) {room2Value = room2Value + (18 - room2Value + Math.random() * -0.5);}
        temperatureData.room2.push({ time, value: room2Value });
    }

    setData([
      { name: "Room 1", tempratures: temperatureData.room1 },
      { name: "Room 2", tempratures: temperatureData.room2 },
    ]);
  }, []);

  return (
    <div className="flex flex-col items-center justify-around">
    <LineChart
      dataset={dataSet}
      xAxis={[{
        id: "Time",
        dataKey: "date",
        scaleType: "time",
        valueFormatter: (value) => new Date(value).toLocaleTimeString(),
      }]}
      series={series}
      width={500}
      height={300}
      grid={{ vertical: true, horizontal: true }}
    />
    </div>
  );
}

export default Temp;