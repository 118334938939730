import { useState, useEffect } from 'react';
import { ChartContainer, LineChart} from '@mui/x-charts';
import { Chip } from '@mui/material';


const VibrationSensor_1 = () => {

  const [isAlarmActive, setIsAlarmActive] = useState(false);
  const [data, setData] = useState([{"frequency": 10.0, "amplitude": 0.02},
    {"frequency": 20.0, "amplitude": 0.03},
    {"frequency": 30.0, "amplitude": 0.04},
    {"frequency": 40.0, "amplitude": 0.05},
    {"frequency": 50.0, "amplitude": 0.07},
    {"frequency": 60.0, "amplitude": 0.09},
    {"frequency": 70.0, "amplitude": 0.15},
    {"frequency": 80.0, "amplitude": 0.22},
    {"frequency": 90.0, "amplitude": 0.35},
    {"frequency": 100.0, "amplitude": 0.48},
    {"frequency": 110.0, "amplitude": 0.65},
    {"frequency": 120.0, "amplitude": 0.78},
    {"frequency": 130.0, "amplitude": 1.02},
    {"frequency": 140.0, "amplitude": 1.25},
    {"frequency": 150.0, "amplitude": 2.50},
    {"frequency": 160.0, "amplitude": 1.20},
    {"frequency": 170.0, "amplitude": 0.80},
    {"frequency": 180.0, "amplitude": 0.60},
    {"frequency": 190.0, "amplitude": 0.50},
    {"frequency": 200.0, "amplitude": 0.40}])

  // Simulate data update
  useEffect(() => {
    const interval = setInterval(() => {
      // Update the waveform data
      const newWaveform = data.map(point => ({
        ...point,
        amplitude: point.amplitude + Math.random() * 0.01
      }));
      setData(newWaveform);
      
    }, 1000); // Adjust the interval duration as needed
  
    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, [data]);

  // Render waveform
  const renderWaveform = () => {

    const heighestAmplitude = Math.max(...data.map(point => point.amplitude));
    const xAxis = data.map(point => point.frequency);
    const series = data.map(point => point.amplitude);
    console.log("series", xAxis, series);

    return (
      <LineChart
      xAxis={[{ scaleType: 'linear', dataKey: 'frequency', label: 'Frequency (Hz)' }]}
      yAxis={[{ scaleType: 'linear', dataKey: 'amplitude', label: 'Amplitude (dBm)', domain: [0, heighestAmplitude] } ]}
      grid={{ vertical: true, horizontal: true }}
      series={[
        {
          dataKey: 'amplitude',
          marker: (point) => {
            return {
              fill: point.amplitude === heighestAmplitude ? 'red' : 'blue',
              r: 5
            }}

        }
      ]}
      dataset={data}
      width={500}
      height={300}
      margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
    />

    )
  };

  return (
    <div className="">
      <div className='flex justify-center'>
        {renderWaveform()}
      </div>
      <div className="flex justify-around items-center">
        <div className="m-2">
          <Chip label={`Peak Amplitude: ${Math.max(...data.map(point => point.amplitude)).toFixed(4)}dBm`} />
        </div>
        <div className="m-2">
          <Chip label={`Peak Frequency: ${data.find(point => point.amplitude === Math.max(...data.map(point => point.amplitude))).frequency.toFixed(4)}Hz`} />
        </div>
      </div>
    </div>
  );
};

export default VibrationSensor_1;