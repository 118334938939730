import { useState } from 'react';

const Flood = (props) => {
  const [floods, setFloods] = useState([
    { id: "8f:08:05:de:e4:5b",
      location: {
        course: ["Main Building", "Ground Floor", "Office"],
        fine: ["East wall", "Near the door"]
      },
      alert: {
        value: false
      },
      heartbeat: {
        last: Date.now() - 1000 * 60 * 60 * 4.2,
        next: Date.now() + 1000 * 60 * 60 * (24 - 4.2)
      }
    },
    { id: "e7:ac:ca:c7:ae:86",
      location: {
        course: ["Main Building", "Ground Floor", "Warehouse"],
        fine: ["South wall", "Beside the stairs"]
      },
      alert: {
        value: true,
        time: {
          first: Date.now() - 1000 * 60 * 60 * 0.4,
          last: Date.now() - 1000 * 60 * 60 * 0.2,
          next: Date.now() + 1000 * 60 * 60 * 0.2
        }
      },
      heartbeat: {
        last: Date.now() - 1000 * 60 * 60 * 0.2,
        next: Date.now() + 1000 * 60 * 60 * 0.8
      }
    },
    { id: "2b:0b:6d:8e:3e:ba",
      location: {
        course: ["Security Hut", "East Entrance"],
        fine: ["North wall", "Behind the desk"]
      },
      alert: {
        value: false
      },
      heartbeat: {
        last: Date.now() - 1000 * 60 * 60 * 8.1,
        next: Date.now() + 1000 * 60 * 60 * (24 - 8.1)
      }
    }
  ]);

  const getTimeDiff = (toCheck) => {
    const now = Date.now();
    const timeDifference = toCheck - now;
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  return (
    <div className="flex flex-col items-center justify-around">
      <div className="flex flex-col w-full">
            {/* Table Header */}
            <div className="flex bg-gray-200 font-bold">
                <div className="flex-1 p-2">Sensor</div>
                <div className="flex-1 p-2">State</div>
            </div>
            {/* Table Rows */}
            {floods.map((item, index) => (
                <div key={index} className="flex border-b">
                    <div className="flex-1 p-2 flex-col, gap-1">
                      <div className="flex flex-row gap-1">
                        {item.location.course.map((loc, index) => (
                          <div key={index} className="text-slate-900 text-md">{loc}{index !== item.location.course.length - 1 && ","}</div>
                        ))}
                      </div>
                      <div className="flex flex-row gap-1">
                        {item.location.fine.map((loc, index) => (
                          <div key={index} className="text-slate-900 text-sm">{loc}{index !== item.location.fine.length - 1 && ","}</div>
                        ))}
                      </div>
                      <div className="text-slate-500 text-xs">ID: {item.id}</div>
                    </div>
                    <div className="flex-1 p-2">
                      {item.alert.value ? (
                        <div className="flex flex-col gap-1">
                            <div className="text-red-500 font-bold">Water Detected</div>
                            <div>
                              <div className="text-slate-500 text-sm">First alert: {new Date(item.alert.time.first).toLocaleString()}</div>
                              <div className="text-slate-500 text-sm">Last alert: {new Date(item.alert.time.last).toLocaleString()}</div>
                              <div className="text-slate-500 text-xs">Next check expected in: {getTimeDiff(item.alert.time.next)}</div>
                            </div>
                        </div>
                      ) : (
                        <div className="flex flex-col">
                          <div className="text-green-500 font-bold">No Water Detected</div>
                          <div className="text-slate-500 text-sm">Last check: {new Date(item.heartbeat.last).toLocaleString()}</div>
                          <div className="text-slate-500 text-sm">Next check expected in: {getTimeDiff(item.heartbeat.next)}</div>
                        </div>
                      )}
                    </div>
                </div>
            ))}
        </div>
    </div>
  );
}

export default Flood;