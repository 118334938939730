import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


console.log("Loading index.js");

const startApp = (containerID, props) => {
  const container = document.getElementById(containerID || "root");
  const reactRoot = ReactDOM.createRoot(container)
  reactRoot.render(
    <React.StrictMode>
      <App {...props}/>
    </React.StrictMode>
  );
}

// Check for test div
if (document.getElementById("test-root")) {
  startApp("test-root");
}

window.startApp = startApp
