import { useState, useEffect } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

const Humidity = (props) => {
  const [data, setData] = useState([]);
  const [dataSet, setDataSet] = useState([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    let tmpDataSet = [];
    let tmpSeries = [];
    data.forEach((room) => {
      tmpSeries.push({ id: room.name, dataKey: room.name, type: "line", showMark: false, label: room.name });
      room.humidities.forEach((humidity) => {
        // Check if the date is already in the dataset
        let dateIndex = tmpDataSet.findIndex((value) => value.date === humidity.time);
        if (dateIndex === -1) {
          tmpDataSet.push({ date: humidity.time, [room.name]: humidity.value });
        } else {
          tmpDataSet[dateIndex][room.name] = humidity.value;
        }
      });
    });
    setDataSet(tmpDataSet);
    setSeries(tmpSeries);

  }, [data]);

  useEffect(() => {
    // Set dummy data
    const timePeriodMins = 15
    const timePeriodTotalMins = 24 * 60
    const timeIntervals = timePeriodTotalMins / timePeriodMins
    const humidityData = {room1: [], room2: []};
    const now = Date.now();
    const minHumidity = 35;
    const maxHumidity = 80;

    for (let i = 0; i < (timeIntervals); i++) {
        const time = now + i * timePeriodMins * 60 * 1000;
        // Simulate humidity rise and fall using a sine wave pattern
        const angle = (i / timeIntervals) * Math.PI * 2;
        const value = Math.sin(angle) * (maxHumidity - minHumidity) / 2 + (maxHumidity + minHumidity) / 2;
        humidityData.room1.push({ time, value: (value + (Math.random() * -0.5)) });
        let room2Value = value + (Math.random() * 0.9) + (i / timeIntervals * 2) * 10;
        if (room2Value > 60) {room2Value = room2Value - (room2Value - 60 + Math.random() * -0.5);}
        if (room2Value < 40) {room2Value = room2Value + (40 - room2Value + Math.random() * -0.5);}
        humidityData.room2.push({ time, value: room2Value });
    }
    
    setData([
      { name: "Room 1", humidities: humidityData.room1 },
      { name: "Room 2", humidities: humidityData.room2 },
    ]);
  }, []);

  return (
    <div className="flex flex-col items-center justify-around">
    <LineChart
      dataset={dataSet}
      xAxis={[{
        id: "Time",
        dataKey: "date",
        scaleType: "time",
        valueFormatter: (value) => new Date(value).toLocaleTimeString(),
      }]}
      series={series}
      width={500}
      height={300}
      grid={{ vertical: true, horizontal: true }}
    />
    </div>
  );
}

export default Humidity;