import React, { useState } from "react";

const SVGTest = () => {
  const [clickedElement, setClickedElement] = useState(null);

  // Example array of circles with positions and colors (could be dynamic)
  const circles = [
    { id: 1, cx: 50, cy: 50, r: 30, fill: "red" },
    { id: 2, cx: 150, cy: 50, r: 30, fill: "green" },
    { id: 3, cx: 250, cy: 50, r: 30, fill: "blue" },
  ];

  const handleClick = (id) => {
    setClickedElement(id);
    console.log(`Circle ${id} clicked!`);
  };

  return (
    <div>
      <h3>Clickable SVG</h3>
      <svg width="300" height="100" style={{ border: "1px solid black" }}>
        {circles.map((circle) => (
          <circle
            key={circle.id}
            cx={circle.cx}
            cy={circle.cy}
            r={circle.r}
            fill={circle.fill}
            onClick={() => handleClick(circle.id)}
            style={{ cursor: "pointer" }}
          />
        ))}
      </svg>

      {clickedElement && (
        <p>You clicked on circle with ID: {clickedElement}</p>
      )}
    </div>
  );
};

export default SVGTest;
