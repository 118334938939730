import { useState } from "react";

export default function History() {

  const [history, setHistory] = useState([{
    id: 1,
    timeStamp: new Date() - 1000 * 60 * 60,
    title: "Door opened",
    description: "A door was opened",
    things: ["door 1"]
  },
  {
    id: 2,
    timeStamp: new Date() - 1000 * 60 * 60 * 2,
    title: "Door closed",
    description: "A door was closed",
    things: ["door 1"]
  },
  {
    id: 3,
    timeStamp: new Date() - 1000 * 60 * 60 * 3,
    title: "Present detected",
    description: "A person was detected",
    things: ["Room 1"]
  },
  {
    id: 4,
    timeStamp: new Date() - 1000 * 60 * 60 * 4,
    title: "Present detected",
    description: "A person was detected",
    things: ["Room 4"]
  }
]);

  const handleHistoryClick = (id) => {
    console.log(`History ${id} clicked`);
    // Go to a new page
    window.location.href = `/history/${id}`;
  }

  const headerClassName = "px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
  const tdClassName = "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900"

  return (
    <div className="container mx-auto">
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className={headerClassName}>Timestamp</th>
            <th className={headerClassName}>Title</th>
            <th className={headerClassName}>Description</th>
            <th className={headerClassName}>Things</th>
          </tr>
        </thead>
        <tbody>
          {history.map(history => (
            <tr key={history.id} className="cursor-pointer hover:bg-gray-100" onClick={() => handleHistoryClick(history.id)}>
              <td className={tdClassName}>{history.timeStamp.toLocaleString()}</td>
              <td className={tdClassName}>{history.title}</td>
              <td className={tdClassName}>{history.description}</td>
              <td className={tdClassName}>{history.things.join(", ")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

}